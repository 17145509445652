<template>
    <el-dialog title="选择需要搜索的颜色" :visible.sync="isPreviewVisible" width="500px" @close="dialogClose()">
        <div class="selectcolor">
            <div class="title">调色板选色</div>
            <div class="colorPicker f-c-b">
                <!-- <el-color-picker v-model="picker_color"></el-color-picker> -->
                <chrome-picker class="chrome_picker" v-model="colors" @input="updateValue"></chrome-picker>
                <div class="colorbanner">
                    <div class="text">当前颜色</div>
                    <div v-if="picker_color" class="new" :style="{ 'background': picker_color }"></div>
                    <div v-else class="new" style="border: 1px dashed;"></div>
                    <div class="old"></div>
                    <div class="text_box">{{ picker_color }}</div>
                </div>
            </div>
            <div class="btn" @click="confirm()">确定</div>
        </div>
    </el-dialog>
</template>

<script>
import { Chrome } from "vue-color"
export default {
    name: '',
    props: {
        pickerColor: {
            type: String,
        },
        PreviewVisible: {
            type: Boolean,
            default: false
        }
    },
    components: {
        "chrome-picker": Chrome
    },
    data() {
        return {
            isPreviewVisible: this.PreviewVisible,
            picker_color: this.pickerColor ? this.pickerColor : this.$getStorage('ColorPalette_color'), // 选中的颜色
            colors: {
                color: "",
                hex: this.pickerColor ? this.pickerColor : this.$getStorage('ColorPalette_color'),
                // hsl: { },
                // hsv: { },
                rgba: {
                    r: '',
                    g: '',
                    b: '',
                    a: '',
                },
            },
        }
    },
    created() {
        this.$removeStorage('fetchDataSize')
        this.$removeStorage('fetchDataPage')
    },
    methods: {
        // 关闭
        dialogClose() {
            this.$emit('handleChangePaletteDialog', false);
            this.$setStorage('ColorPalette_color', this.picker_color);
        },
        // 确定
        confirm() {
            this.$emit('handleChangePaletteDialog', false, this.picker_color);
            if (this.$getStorage('ColorPalette_color')) {
                this.$removeStorage('ColorPalette_color');
            }

        },
        updateValue(value) {
            this.picker_color = value.hex;
        },
    },
    // beforeDestroy() {
    //     this.$removeStorage('fetchDataSize')
    //     this.$removeStorage('fetchDataPage')
    // }
}

</script>
<style lang='less' scoped>
/deep/ .el-dialog {
    border-radius: 10px;
}

.selectcolor {
    .title {
        font-size: 24px;
        font-weight: 500;
        color: #000000;
    }

    .colorPicker {
        margin-top: 30px;

        /deep/.vc-chrome-toggle-btn {
            display: none;
        }

        .chrome_picker {
            margin-right: 20px;
        }

        .colorbanner {
            .text {
                text-align: center;
                font-size: 16px;
                font-weight: 400;
                color: #333333;
                line-height: 13px;
            }

            .new {
                width: 127px;
                height: 160px;
                border-radius: 20px;
                margin-top: 10px;
            }

            .text_box {
                margin-top: 15px;
                text-align: center;
                line-height: 50px;
                width: 130px;
                height: 50px;
                background: #F8F8F8;
                border-radius: 10px;
            }
        }
    }

    .btn {
        margin: 20px auto 0;
        width: 100px;
        height: 40px;
        text-align: center;
        line-height: 40px;
        border-radius: 5px;
        color: #FFFFFF;
        background: linear-gradient(79deg, #0873F4 0%, #39A8F9 100%);
        cursor: pointer;
    }


}
</style>
