<template>
    <el-dialog title="选择需要搜索的颜色" :visible.sync="isPreviewVisible" width="1300px" @close="dialogClose()">
        <div class="dialog_box flex">
            <div class="colorlibrary_selection f-c-b">
                <div class="selection_top">
                    <div class="title">色库选色</div>
                    <div class="select">
                        {{ colorLibraryName }}
                        <div class="select_btn" @click="isOptions = !isOptions">更多色库 <i class="el-icon-arrow-down"></i>
                        </div>
                        <div v-show="isOptions" class="options">
                            <div class="options_item" v-for="(item, index) in colorLibraryList" :key="index"
                                @click="colorLibraryName = item.csName, isOptions = false">
                                {{ item.csName }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="selection_botton">
                    <div class="title">搜索颜色</div>
                    <div class="options">
                        <span style="margin-right: 30px;" @click="ColorDetails.colorState = 1"
                            :class="ColorDetails.colorState == 1 ? 'pitchOn' : 'NopitchOn'">搜名称</span>
                        <span @click="ColorDetails.colorState = 2"
                            :class="ColorDetails.colorState == 2 ? 'pitchOn' : 'NopitchOn'">搜编号</span>
                    </div>
                    <el-input style="width: 400px;" v-if="ColorDetails.colorState == 1" maxlength="20"
                        v-model="ColorDetails.colorSearch" placeholder="请输入名称"></el-input>
                    <el-input style="width: 400px;" v-else-if="ColorDetails.colorState == 2" maxlength="20"
                        v-model="ColorDetails.colorSearch" placeholder="请输入编号"></el-input>
                </div>
            </div>
        </div>
        <div class="btnBox f-c-c">
            <div @click="search()" class="SearchBtn">搜索</div>
            <div class="ResetBtn" @click="reset()">重置</div>
        </div>
        <div class="colorList">
            <div class="title">颜色列表</div>
            <div v-show="tipColorName && colorList.length != 0" class="tip">已搜索到与“{{ tipColorName }}”相似颜色{{ total }}条</div>
            <div v-if="colorList.length !== 0" class="color_list f-w">
                <div class="color_item" v-for="(item, index) in colorList" :key="index" @click="selectColor(item)">
                    <div class="colorblock" :style="{ backgroundColor: `#${item.rgbValue}` }"></div>
                    <div class="color_text f-c-b">
                        <div>{{ item.colorName }}</div>
                        <div>{{ item.colorNumber }}</div>
                    </div>
                </div>
            </div>
            <div v-else class="f-fr-jc empty">
                <img src="@/assets/loading.png" alt="">
                <span>未搜索到相近颜色</span>
            </div>
        </div>
        <Pagination v-if="colorList && colorList.length" :total="total" :pageNum.sync="currentPage" :limit.sync="size"
            :layout="layout" @pagination="fetchData" />
    </el-dialog>
</template>

<script>
import CryptoJS from '@/utils/DES.js';
// import RGB2Lab from '@/utils/RGB2Lab';
import { PaginationRetention } from '@/mixins/PaginationRetentionMixin';
export default {
    name: '',
    props: {
        pickerColor: {
            type: String,
        },
        PreviewVisible: {
            type: Boolean,
            default: false
        }
    },
    mixins: [PaginationRetention],
    data() {
        return {
            isPreviewVisible: this.PreviewVisible,
            picker_color: this.pickerColor, // 选中的颜色
            picker_colorText: '',
            isOptions: false, // 色库选项
            colorLibraryList: '',  // 色库列表
            colorLibraryName: '',  // 当前色库名
            tipColorName: '',
            colorList: [],
            ColorDetails: {
                csId: 1,
                colorSearch: '',
                labL: '',
                labA: '',
                labB: '',
                colorState: 1,
                page: 1,
                size: 8
            },

        }
    },
    created() {
        this.findSearchColorLibraryList();
    },
    methods: {
        getDate() {
            let colorSearch = this.$getStorage('ColorSearchValue') ?? '',
                colorState = this.$getStorage('ColorStateValue') ?? '',
                labValue = this.$getStorage('labValue') ?? '';
            this.findSearchColorDetailsList(this.paging, this.pagingSize, colorSearch, colorState, labValue);

        },
        // 获取色库列表
        async findSearchColorLibraryList() {
            await this.$http.findSearchColorLibraryList({
                csName: "",
                page: 1,
                size: 10
            }).then(res => {
                if (res.code == 200) {
                    let records = res.data.records;
                    this.colorLibraryList = records;
                    this.colorLibraryName = records[0].csName;
                }
            })
        },
        async findSearchColorDetailsList(page = this.page, size = this.size, colorSearch, colorState, labValue) {
            await this.$http.findSearchColorDetailsList({
                csId: 1,
                colorSearch,
                labL: labValue ? labValue.labL : '',
                labA: labValue ? labValue.labA : '',
                labB: labValue ? labValue.labB : '',
                colorState,
                page,
                size
            }).then(res => {
                if (res.code == 200) {
                    let records = res.data.records;
                    records.forEach(item => {
                        item.rgbValue = CryptoJS.decrypt(item.rgbValue)
                    })
                    this.colorList = records;
                    this.currentPage = res.data.current;
                    this.total = res.data.total;
                }
            })
        },
        // 搜索
        search() {
            let data = this.ColorDetails;
            this.$setStorage('ColorSearchValue', data.colorSearch)
            this.$setStorage('ColorStateValue', data.colorState)
            this.findSearchColorDetailsList(1, this.size, data.colorSearch, data.colorState)
        },
        // 重置
        reset() {
            this.picker_color = '';
            this.tipColorName = '';
            this.ColorDetails = {
                csId: 1,
                colorSearch: '',
                labL: '',
                labA: '',
                labB: '',
                colorState: 1,
                page: 1,
                size: 10
            }
            this.findSearchColorDetailsList();
        },
        // 
        selectColor(item) {
            console.log(item)
            this.$emit('handleChangeDialog', false, `#${item.rgbValue}`, item.colorNumber, item.colorId,item.colorName);
        },
        // 
        dialogClose() {
            this.$emit('handleChangeDialog', false, this.picker_color);

        }
    },
}

</script>
<style lang='less' scoped>
/deep/ .el-dialog {
    border-radius: 10px;
}

.dialog_box {
    padding: 0 130px;

    .selectcolor {
        width: 50%;

        .title {
            font-size: 24px;
            font-weight: 500;
            color: #000000;
        }

        .colorPicker {
            margin-top: 30px;

            .colorbanner {
                .text {
                    text-align: center;
                    font-size: 16px;
                    font-weight: 400;
                    color: #333333;
                    line-height: 13px;
                }

                .new {
                    width: 127px;
                    height: 160px;
                    border-radius: 20px;
                    margin-top: 10px;
                }

                .text_box {
                    margin-top: 15px;
                    text-align: center;
                    line-height: 50px;
                    width: 130px;
                    height: 50px;
                    background: #F8F8F8;
                    border-radius: 10px;
                }
            }
        }

    }

    .colorlibrary_selection {
        width: 100%;

        .selection_top {
            .title {
                font-size: 24px;
                font-weight: 500;
                color: #000000;
            }

            .select {
                padding: 10px 20px;
                margin-top: 70px;
                width: 314px;
                height: 30px;
                line-height: 30px;
                background: #F8F8F8;
                border-radius: 10px 10px 0 0;
                font-size: 16px;
                font-weight: 400;
                color: #999999;
                position: relative;

            }

            .select_btn {
                position: absolute;
                right: 20px;
                top: 10px;
                width: 100px;
                height: 30px;
                line-height: 30px;
                border-radius: 15px;
                text-align: center;
                color: #FFFFFF;
                background: linear-gradient(90deg, #0873F4 0%, #39A8F9 100%);
                cursor: pointer;
            }

            .options {
                position: absolute;
                top: 50px;
                left: 0;
                overflow-y: scroll;
                white-space: nowrap;
                line-height: 30px;
                background: #F8F8F8;
                border-radius: 0 0 10px 10px;
                font-size: 16px;
                font-weight: 400;
                color: #999999;

                .options_item {
                    border-top: 1px solid #D8D8D8;
                    ;
                    padding: 10px 20px;
                    width: 314px;
                    height: 30px;
                    font-size: 16px;
                    font-weight: 400;
                    color: #999999;
                    text-align: center;
                    line-height: 30px;
                    cursor: pointer;
                    z-index: 99;
                }
            }

            .options::-webkit-scrollbar {
                display: none;
                /* Chrome Safari */
            }

            .options {
                scrollbar-width: none;
                /* firefox /
                    -ms-overflow-style: none; / IE 10+ */
                overflow-y: auto;
            }
        }

        .selection_botton {
            .title {
                font-size: 24px;
                font-weight: 500;
                color: #000000;
            }

            .options {
                padding: 10px 0;
                font-size: 14px;
                margin: 20px 0;
                cursor: pointer;

                .pitchOn {
                    padding: 5px 0;
                    color: #000000;
                    border-bottom: 2px solid #000000;
                }

                .NopitchOn {
                    padding: 5px 0;
                    color: #999999;
                }
            }
        }
    }
}

.btnBox {
    margin-top: 55px;

    .SearchBtn {
        color: #FFFFFF;
        font-size: 16px;
        height: 40px;
        width: 125px;
        line-height: 40px;
        text-align: center;
        background: linear-gradient(90deg, #0873F4 0%, #39A8F9 100%);
        border-radius: 10px;
        cursor: pointer;
        margin-right: 60px;
    }

    .ResetBtn {
        height: 40px;
        width: 125px;
        line-height: 40px;
        color: #0873F4;
        text-align: center;
        background: #f8f8f8;
        border-radius: 10px;
        cursor: pointer;
    }
}

.colorList {
    .title {
        font-size: 24px;
        font-weight: 500;
        color: #000000;
    }

    .tip {
        margin-top: 16px;
        font-size: 16px;
        font-weight: 500;
        color: #999999;
    }

    .color_list {
        margin-top: 30px;

        .color_item {
            padding: 15px;
            width: 215px;
            background: #F8F8F8;
            border-radius: 10px;
            margin-right: 5px;
            margin-bottom: 10px;

            .colorblock {
                width: 215px;
                height: 60px;
                background: #FFD8A9;
                border-radius: 10px;
            }

            .color_text {
                margin-top: 15px;
                font-size: 16px;
                color: #333333;
            }
        }
    }

    .empty {
        margin-top: 65px;

        img {
            width: 20px;
            height: 20px;
        }

        span {
            margin-top: 15px;
            color: #999999;
            font-size: 16px;
        }

        .btn {
            margin-top: 20px;
            width: 80px;
            height: 40px;
            line-height: 40px;
            color: #FFFFFF;
            text-align: center;
            background: linear-gradient(90deg, #0873F4 0%, #39A8F9 100%);
            border-radius: 10px;
            cursor: pointer;
        }
    }
}
</style>
